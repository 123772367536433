<template>
  <div ref="pageBox">
    <LabelContent title="认证审核" ref="LabelContent">
      <div slot="btns">
        <!-- <el-button
          size="small"
          type="warning"
          @click="batchDelete"
          :disabled="batchDeleteArr.length === 0"
        >
          批量移除
        </el-button>-->
      </div>
    </LabelContent>
    <div class="tableBox">
      <el-table
        stripe
        ref="multipleTable"
        :data="tableData"
        class="center"
        :height="tableHeight + 'px'"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
        :default-sort="{ prop: 'value', order: 'descending' }"
      >
        <!-- <el-table-column type="selection" width="55" /> -->
        <el-table-column prop="name" label="真实姓名" :sort-method="sortByDate" />
        <el-table-column prop="idCard" label="身份证号" />
        <el-table-column label="毕业证或录取通知书图片" show-overflow-tooltip>
          <template slot-scope="slot">
            <div v-if="slot.row.hasOwnProperty('diplomaImg')" class="flex">
              <el-image :src="slot.row.diplomaImg">
                <div slot="error">
                  <img src="../../assets/imgs/diushi.png" />
                </div>
              </el-image>
            </div>
            <img v-else src="../../assets/imgs/diushi.png" />
          </template>
        </el-table-column>
        <el-table-column label="相关证件图片" show-overflow-tooltip>
          <template slot-scope="slot">
            <el-image
              v-if="slot.row.hasOwnProperty('files')"
              :preview-src-list="[slot.row.files[0].updUrl]"
              :src="slot.row.files[0].updUrl"
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="auditStatus.desc" label="审核状态" />
        <el-table-column label="操作" width="100">
          <template slot-scope="scopes">
            <el-button type="primary" @click="edit(scopes.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="审核" :visible.sync="dialogFormVisible">
        <el-form style="display: flex; flex-wrap: wrap">
          <el-form-item label="真实姓名：" class="stywid">
            <p>{{ setadmin.name }}</p>
          </el-form-item>
          <el-form-item label="身份证号：" :label-width="formLabelWidth" class="stywid">
            <p>{{ setadmin.idCard }}</p>
          </el-form-item>
          <el-form-item
            label="毕业证或录取通知书图片："
            :label-width="formLabelWidth"
            class="stywid"
          >
            <div v-if="setadmin.hasOwnProperty('diplomaImg')" class="flex">
              <el-image :src="setadmin.diplomaImg"></el-image>
            </div>
            <img
              v-else
              style="text-align: center; width: 10vw; height: 10vw"
              src="../../assets/imgs/diushi.png"
            />
          </el-form-item>
          <el-form-item label="相关证件图片：" :label-width="formLabelWidth" class="stywid">
            <div v-if="setadmin.hasOwnProperty('files')" class="flex">
              <div v-for="item in setadmin.files" :key="item.updId">
                <el-image :src="item.updUrl"></el-image>
              </div>
            </div>
            <img v-else style="width: 10vw; height: 10vw" src="../../assets/imgs/diushi.png" />
          </el-form-item>
          <el-form-item label="审核：" style="margin-left: 1vw" class="stywid stywid1">
            <el-radio v-model="Submitquery.auditStatus" label="success" value="success"
              >通过</el-radio
            >
            <el-radio v-model="Submitquery.auditStatus" label="fail" value="fail">驳回</el-radio>
          </el-form-item>
          <el-form-item style="margin-left: 1vw" label="备注:">
            <el-input
              :rows="3"
              :cols="1"
              placeholder="请输入内容"
              v-model="Submitquery.remark"
              type="textarea"
              width="30vw"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit()">确 定</el-button>
        </div>
      </el-dialog>
      <el-pagination
        @size-change="paginationChange"
        @current-change="paginationChange"
        :current-page="query.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "Factory",
  data () {
    return {
      formLabelWidth: 100,
      tableHeight: 0, //表格高度
      tableData: [],
      total: 0,
      dialogFormVisible: false,
      setadmin: {
        username: "",
        idCard: "",
        diplomaImg: "",
        userfiles: [],
        auditStatus: [],
      },
      Submitquery: {
        artId: 0,  //审核数据的id
        auditStatus: "success",  //是否通过
        remark: "",  //备注
      },
      query: {
        name: "",
        pageNum: 1,
        pageSize: 10,
        school: "",
        timeEnd: "",
        timeStart: "",
        formLabelWidth: "120px",
      },
    }
  },
  mounted () {
    //设置表格高度
    this.$nextTick(() => {
      let page = this.$refs.pageBox.offsetHeight || 0 //卡片高度
      let SearchBox = this.$refs.SearchBox
        ? this.$refs.SearchBox.$el.offsetHeight
        : 0 //搜索框高度
      let LabelContent = this.$refs.LabelContent
        ? this.$refs.LabelContent.$el.offsetHeight
        : 0 //内容标题高度
      this.tableHeight = page - (SearchBox + LabelContent + 20 + 30) //padding -20,分页 -30
    })
    this.getrealname()
  },
  methods: {
    async edit (row) {
      console.log(row)
      this.setadmin = row
      this.dialogFormVisible = true
    },
    //获取实名认证列表信息
    async getrealname () {
      let that = this
      let result = await that.$http.request({
        url: "/hw/admin/user/cert/page",
        method: "GET",
        params: that.query,
      })
      if (result.status == 100) {
        let { data } = result
        that.tableData = data.list
        that.total = data.total
        that.loading = false
      }
    },
    //审核
    async submit () {
      this.Submitquery.artId = this.setadmin.certId
      let data = await this.$http
        .request({
          url: "/hw/admin/user/audit_cert/" + this.Submitquery.artId,
          method: "POST",
          params: this.Submitquery,
          showLoading: true,
        })
      if (data.status == 100) {
        this.$message({
          message: '审核成功',
          type: 'success'
        })
        this.dialogFormVisible = false
      }
    },
    //批量移除
    // batchDelete() {
    //   let deleteData = this.batchDeleteArr.map((item) => {
    //     const { server_asset_id } = item;
    //     return { server_asset_id };
    //   });
    //   this.deleteApi(deleteData);
    // },
    deleteApi (data) {
      const _this = this
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => { })
    },
    //分页
    paginationChange (val) {
      console.log(val)
    },
    //表格行过滤
    formatterRow (row, column) {
    },
    //多选框选择 返回当前已选行数据(Array)
    handleSelectionChange (val) {
      // this.batchDeleteArr = val;  //批量移除
      console.log(val)
    },
    //从后台获取数据,重新排序
    sortChange (val) {
      // 详细文档参考： https://element.eleme.cn/2.13/#/zh-CN/component/table
      console.log(val)
    },
    //自定义排序规则 sort-method
    sortByDate (a, b) {
      console.log(a, b)
      if (a < b) return -1
    },
    getuserinfo () {

    },

    //跳转审核
    tableDetail (row) {
      this.$router.push({
        path: "/testPage_detail/" + row.certId,
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.batchdel {
  display: none;
}
.el-button--primary {
  width: 4.5vw;
  height: 2vw;
  line-height: 0px;
}
.stywid {
  margin-left: 1vw;
  width: 100%;
}
.el-form .el-input,
.el-form .el-textarea {
  width: 60vw;
  max-height: 30vw;
  max-width: 30vw;
}
.el-form .el-input .el-input__inner,
.el-form .el-input .el-textarea__inner,
.el-form .el-textarea .el-input__inner,
.el-form .el-textarea .el-textarea__inner {
  width: 60vw;
}
.el-table td div {
  justify-content: center;
}
</style>
